
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Router from './components/Router';
// import Index1 from './components/Index1';

function App() {
  return (
<div>
  
  <Header />
  <Router />
  <Footer />
  
</div>
  );
}

export default App;
