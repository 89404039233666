import React from 'react';

function Header() {
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    };

    return (
        <div>
            <header>
                <div className="container">
                    <div className="header-row">
                        <a className="header-logo" href="#" style={{  display: 'block', width: '50rem',    marginLeft: "-3rem" }}>
                            <h3>JUST COOL AC REPAIRS SERVICE</h3>
                        </a>
                        <div className="mobile-menu">
                            <div className="header-nav-wrapper">
                                <button className="btn-plain toggle-header-nav">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                <ul className="header-nav">
                                    <li>
                                        <a className="header-nav-link" href="#">Home</a>
                                    </li>
                                    <li>
                                        <a className="header-nav-link" href="#" onClick={scrollToBottom}>About</a>
                                    </li>
                                    <li>
                                        <a className="header-nav-link" href="#">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <a className="header-contact" href="tel:+971 50 525 1159">
                                <i className="icon icon-phone"></i>
                                <span>|</span>
                                <span style={{ whiteSpace: 'nowrap' }}>+91 8958280979</span>
                            </a>
                        </div>
                        <button className="btn-plain toggle-mobile-menu">
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Header;
