import React, { Component } from 'react'

export class Home extends Component {
    render() {
        return (
            <div>
                <div className="page-wrapper main-page style=background:img ">
                    <div className="main-slider-wrapper">
                        {/* <div className="request-container ">
                            <div className="container">
                            </div>
                        </div> */}
                        <div className="swiper main-slider">
                            <div className="swiper-wrapper">

                                {/* <div className="swiper-slide main-slider-item" >F:\STS_WorkSpace\New folder\sunroof\public\images\prRiR1oIUBbnthH67oOd.jpg */}
                                <div className="swiper-slide main-slider-item" style={{ background: 'url(images/prRiR1oIUBbnthH67oOd.jpg)', backgroundRepeat: "no-repeat", backgroundSize: "center/cover" }}>
                                    <div className="container">
                                        <div className="main-slider-content" style={{ alignContent: "center", alignItems: "center", display: "block" }}>
                                            <p><h1>Best AC Service In Noida. AC Installation And AC Repair.</h1></p>
                                            <br />
                                            <br />
                                            <p>We Provide best in class AC repair, servicing & installation services at your doorsteps at most affordable prices. Just give us a call and our technical expert will be there at your place at your desired time to fix the issue with your AC. AC repair service in Noida, AC installation service in Noida, AC installation in Noida.</p>
                                            <br />
                                            <br />

                                            <br />
                                            <br />
                                            <h4><p>* AC Repair in Noida</p>
                                                <p>* AC Installation in Noida</p>
                                                <p>* AC Servicing & Maintenance</p>
                                                <p>* Service At Your Doorsteps</p>
                                                <p>* Service Within 30 Minutes</p>
                                                <p>* Trained Professionals & .Genuine Spares </p>
                                                <p>* Call us at +918958280979</p>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />

                    <div class="pk">
                        <table >
                            <tr>
                                <th>Services name </th>
                                <th>charges</th>
                            </tr>
                            <tr>
                                <td>Sprite AC Installation</td>
                                <td>1500 Rs.</td>
                            </tr>
                            <tr>
                                <td>Window AC Installation</td>
                                <td>500 Rs.</td>
                            </tr>
                            <tr>
                                <td>Sprite AC Uninstallation</td>
                                <td>500 Rs.</td>
                            </tr>
                            <tr>
                                <td>Window AC Uninstallation</td>
                                <td>400 Rs.</td>
                            </tr>
                            <tr>
                                <td>Fridge visit</td>
                                <td>150 Rs.</td>
                            </tr>
                            <tr>
                                <td>Washing Machine visit</td>
                                <td>150 Rs.</td>
                            </tr>
                        </table>

                    </div>
                    <div className="service" id="service">
                        <div className="container container-sm">
                            <div className="row service-content">
                                <div className="col-sm-12 service-right" >
                                    <h1>AC Service in Noida</h1> <br />
                                    <p><strong>Arbat Realty</strong> <p>Just Cool AC Service provides premier AC installation and repair services in Noida at unbeatable prices. As your one-stop solution for all AC servicing needs in Noida, we understand the importance of ensuring your air conditioner works flawlessly, especially on scorching summer days. Our team of highly professional AC technicians is dedicated to swiftly resolving any issues with your air conditioner, guaranteeing optimal performance and comfort. </p>
                                        {/* <p>We operate 24/7 and offer phone/online booking facilities for the convenience of the customers. Also, We provide prompt, professional and honest AC Installation/Uninstallation Service and AC Repairing Services to all varieties of ACs like Window AC, Split AC & Cassette AC to top brands air conditioners. Our specialty includes timely service, affordable prices, verified professional experts & great offers. Book our AC service today get your AC fixed or repaired.</p>
                                        <p>Keep your AC cooling all summer with the Best AC Repair and Service in Noida from Service Ninjas. Our highly skilled AC Repair and Service Professionals are well trained and experienced in providing detailed air conditioner repair and service of Split AC, Window AC, Multi-unit central AC or a larger commercial HVAC unit at best ac servicing charges. Book AC service in Noida, AC repair service in Noida, AC installation in Noida, AC repair in Noida, AC Installation
                                            service in Noida, samsung ac service in Noida, lg ac service in Noida, ac repair near me, ac repair services near me, AC installation service & AC servicing in Noida and Greater Noida today and discounted prices..</p>
                                </div> */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper bg-gray">
                        <br />
                        <div className="container container-sm">
                            <div className="cards-wrapper offplan-latest">
                                <div className="cards-wrapper-heading">
                                    <h2>Our AC Services</h2>
                                </div>
                                <div className="apartments-card-wrapper">
                                    <div className="apartments-card-sm">
                                        <div className="apartments-img">
                                            <img src="images/AC_repair.jpg"
                                                alt="Bayview at Emaar Beachfront" />
                                        </div>
                                        <div className="card-caption">
                                            <div className="apartments-title">
                                                <p>AC Repair Noida</p><br />
                                                <h5> <p>We can visit your place and fix your AC in no time and at most affordable prices. AC repair services in Noida.</p></h5>
                                            </div>

                                        </div>
                                        <a href="" className="absolute-link"></a>
                                    </div>
                                    <div className="apartments-card-sm">
                                        <div className="apartments-img">
                                            <img src="images/AC_installation.jpg"
                                                alt="" />
                                        </div>
                                        <div className="card-caption">
                                            <div className="apartments-title">
                                                <p>AC Installation in Noida</p>
                                                <br />
                                                <h5> <p>Need your AC installed, whether it's a new unit or relocating an existing one? Our experts will handle it swiftly. AC Installation Service - done right, done fast.</p></h5>
                                            </div>
                                        </div>
                                        <a href="" className="absolute-link"></a>
                                    </div>
                                    <div className="apartments-card-sm">
                                        <div className="apartments-img">
                                            <img src="images/AC_service.jpg"
                                                alt="Seapoint At Emaar Beachfront" />
                                            <div>
                                            </div>
                                        </div>
                                        <div className="card-caption">
                                            <div className="apartments-title">
                                                <p>AC Service</p><br />
                                                <h5><p>Need AC AMC for your home or office? We've got you covered. Call us now.</p></h5>
                                            </div>
                                        </div>
                                        <a href="" className="absolute-link"></a>
                                    </div>
                                    <div className="apartments-card-sm">
                                        <div className="apartments-img">
                                            <img src="images/Fridge2.jpg"
                                            />
                                        </div>
                                        <div className="card-caption">
                                            <div className="apartments-title">
                                                <p>Fridge Service</p><br />
                                                <h5><p>Do your Fridge needs servicing. Call us now to get your fridge serviced by our experts at most affordable prices.</p></h5>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="apartments-card-sm">
                                        <div className="apartments-img">
                                            <img src="images/washing.jpg"
                                                alt="Bluewaters Bay" />
                                            <div>
                                            </div>
                                        </div>
                                        <div className="card-caption">
                                            <div className="apartments-title">
                                                <p>Washing Machine Service</p><br />
                                                <h5><p>Is your washing machine in need of servicing? Call us now to have our experts provide top-quality servicing at the most affordable prices.</p></h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="apartments-card-sm">
                                        <div className="apartments-img">
                                            <img src="images/AC_unistallation.jpg"
                                                alt="" />
                                        </div>
                                        <div className="card-caption">
                                            <div className="apartments-title">
                                                <p>AC Uninstallation</p><br />
                                                <h5><p>Need to uninstall your AC unit? Contact us now for efficient and affordable AC uninstallation services.</p></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="project-slider swiper">
                                <div class="owl-carousel owl-theme">
                                    <div class="project-slider-item " style={{ width: "20rem" }}>
                                        <a class="project-slider-img" data-fancybox="gallery" shadow-md
                                            href="../images/AC_installation_2.jpg" style={{ display: "block", width: "20rem", rounded: "[0.3rem] " }}>
                                            <img src="../images/AC_installation_2.jpg" alt="" />
                                        </a>
                                        <div className="card-caption">
                                            <div className="apartments-title">
                                                <h5> <p>AC Installation</p></h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-slider-item">
                                        <a class="project-slider-img" data-fancybox="gallery"
                                            href="../images/AC_unistallation_2.jpg" style={{ display: "block", width: "20rem", rounded: "[0.3rem]" }}>
                                            <img src="../images/AC_unistallation_2.jpg" alt="" />
                                        </a>
                                        <div className="card-caption">
                                            <div className="apartments-title">
                                                <h5> <p>AC Uninstallation</p></h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-slider-item">
                                        <a class="project-slider-img" data-fancybox="gallery"
                                            href="../images/fridge_2.jpg" style={{ display: "block", width: "20rem", rounded: "[0.3rem]" }}>
                                            <img src="../images/fridge_2.jpg" alt="" />
                                        </a>
                                        <div className="card-caption">
                                            <div className="apartments-title">
                                                <h5> <p>Fridge Repair</p></h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-slider-item">
                                        <a class="project-slider-img" data-fancybox="gallery"
                                            href="../images/washing_2.jpg" style={{ display: "block", width: "20rem", rounded: "[0.3rem]" }}>
                                            <img src="../images/washing_2.jpg" alt="" />
                                        </a>
                                        <div className="card-caption">
                                            <div className="apartments-title">
                                                <h5> <p>Washing Machine Service.</p></h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-slider-item">
                                        <a class="project-slider-img" data-fancybox="gallery"
                                            href="../images/AC_service_2.jpg" style={{ display: "block", width: "20rem", rounded: "[0.3rem]" }}>
                                            <img src="../images/AC_service_2.jpg" alt="" />

                                        </a>
                                        <div className="card-caption">
                                            <div className="apartments-title">
                                                <h5> <p>AC Service.</p></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="container container-sm">

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default Home
