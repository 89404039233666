import React from 'react'

function Footer() {
  return (
    <div>
        <footer>
            <div className="container">
                <div className="footer-row">
                    <a className="footer-logo" href="#"
                    style={{ marginRight: '30px', display: 'block', width:'55%'}}>
                        {/* <img src="storage/settings/June2022/quent1s4hqUEeDtNIKGC.png" alt style={{width:"inherit"}} /> */}
                      <div className=''style={{width:"45rem", display:"flex"}}>
                       <h1> <p1>JUST COOL AC REPAIRS SERVICE</p1> </h1>
                       </div>
                    </a>
                    <div className="footer-menu">
                        <div className="footer-menu-item">
                            <h6>Company</h6>
                            <ul className="footer-nav">
                                <li><a href="#">Employees</a>
                                </li>
                                <li><a href="#">About us</a>
                                </li>
                               
                                
                            </ul>

                        </div>
                        <div className="footer-menu-item">
                            <h6>Information</h6>
                            <ul className="footer-nav">
                              
                                <li><a href="#">Home</a>
                                </li>
                                <li><a href="#">Services</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-contact">
                        <a className="footer-contact-phone" href="tel:+918958280979">
                            <i className="icon icon-phone"></i>
                            <span>+918958280979</span>
                        </a>
                        <div className="footer-contact-item">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.o33000rg/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M3.16699 6.76537C3.16699 5.08717 3.78023 3.8668 4.66924 3.06193C5.56676 2.24936 6.77604 1.83325 8.00032 1.83325C9.22461 1.83326 10.4339 2.24937 11.3314 3.06195C12.2204 3.86682 12.8337 5.08718 12.8337 6.76537C12.8337 8.28925 12.1959 9.68142 11.3428 10.8596C10.4905 12.0365 9.44676 12.967 8.695 13.5557C8.21911 13.9283 8.14048 13.9645 8.00047 13.9645C7.86046 13.9645 7.78182 13.9283 7.30593 13.5557C6.55412 12.967 5.51027 12.0365 4.65796 10.8596C3.80478 9.68143 3.16699 8.28925 3.16699 6.76537ZM13.8337 6.76537C13.8337 4.82217 13.1136 3.32647 12.0026 2.32063C10.9001 1.3225 9.4427 0.833256 8.00033 0.833252C6.55795 0.833248 5.10056 1.32248 3.99808 2.32062C2.88709 3.32646 2.16699 4.82215 2.16699 6.76537C2.16699 8.57253 2.92209 10.1675 3.84802 11.4461C4.77483 12.7259 5.89631 13.722 6.68941 14.343L6.75289 14.3929C7.12935 14.6889 7.47975 14.9645 8.00048 14.9645C8.52122 14.9645 8.87161 14.6889 9.24805 14.3928L9.31154 14.343C10.1046 13.7219 11.226 12.7259 12.1527 11.4461C13.0786 10.1674 13.8337 8.5725 13.8337 6.76537ZM6.50033 6.66661C6.50033 5.83818 7.1719 5.16661 8.00033 5.16661C8.82875 5.16661 9.50033 5.83818 9.50033 6.66661C9.50033 7.49503 8.82875 8.16661 8.00033 8.16661C7.1719 8.16661 6.50033 7.49503 6.50033 6.66661ZM8.00033 4.16661C6.61961 4.16661 5.50033 5.28589 5.50033 6.66661C5.50033 8.04732 6.61961 9.1666 8.00033 9.1666C9.38104 9.1666 10.5003 8.04732 10.5003 6.66661C10.5003 5.28589 9.38104 4.16661 8.00033 4.16661Z"
                                    fill="#8E8E8E" />
                            </svg>
                            <span>Noida Sector 78 , UP India</span>
                        </div>
                        <a className="footer-contact-item" href="mailto:mohan.ac.service.noida@gmail.com">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M6.66635 2.16676L6.57275 2.16675C5.42199 2.16665 4.74125 2.16658 4.16833 2.32009C2.61542 2.7362 1.40245 3.94916 0.986353 5.50207C0.832841 6.07499 0.832905 6.75573 0.833013 7.90649L0.833019 8.00009L0.833013 8.09369C0.832905 9.24445 0.832841 9.9252 0.986353 10.4981C1.40245 12.051 2.61542 13.264 4.16833 13.6801C4.74125 13.8336 5.42199 13.8335 6.57275 13.8334L6.66635 13.8334H9.33302L9.42662 13.8334C10.5774 13.8335 11.2581 13.8336 11.831 13.6801C13.384 13.264 14.5969 12.051 15.013 10.4981C15.1665 9.9252 15.1665 9.24445 15.1664 8.09369L15.1664 8.00009L15.1664 7.90649C15.1665 6.75573 15.1665 6.07499 15.013 5.50207C14.5969 3.94916 13.384 2.7362 11.831 2.32009C11.2581 2.16658 10.5774 2.16665 9.42663 2.16675L9.33302 2.16676H6.66635ZM4.42715 3.28602C4.85621 3.17105 5.3942 3.16676 6.66635 3.16676H9.33302C10.6052 3.16676 11.1432 3.17105 11.5722 3.28602C12.78 3.60965 13.7235 4.55307 14.0471 5.76089C14.1621 6.18995 14.1664 6.72794 14.1664 8.00009C14.1664 9.27224 14.1621 9.81024 14.0471 10.2393C13.7235 11.4471 12.78 12.3905 11.5722 12.7142C11.1432 12.8291 10.6052 12.8334 9.33302 12.8334H6.66635C5.3942 12.8334 4.85621 12.8291 4.42715 12.7142C3.21933 12.3905 2.27591 11.4471 1.95228 10.2393C1.83731 9.81024 1.83302 9.27224 1.83302 8.00009C1.83302 6.72794 1.83731 6.18995 1.95228 5.76089C2.27591 4.55307 3.21933 3.60965 4.42715 3.28602ZM4.27704 4.9174C4.04727 4.76422 3.73684 4.82631 3.58366 5.05608C3.43048 5.28584 3.49257 5.59627 3.72234 5.74945L7.72234 8.41612C7.89029 8.52808 8.10909 8.52808 8.27704 8.41612L12.277 5.74945C12.5068 5.59627 12.5689 5.28584 12.4157 5.05608C12.2625 4.82631 11.9521 4.76422 11.7223 4.9174L7.99969 7.39917L4.27704 4.9174Z"
                                    fill="#8E8E8E" />
                            </svg>
                            <span>mohan.ac.service.noida@gmail.com</span>
                        </a>
                    </div>
                    
                    
                </div>
            </div>
        </footer>
    </div>
  )
}

export default Footer
