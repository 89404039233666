import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'


import Home from './Home'
import Contect from './Contect'


function Router() {
    return (
        <>  
            {/* <Header /> */}
            <BrowserRouter>
                <Routes>
                    
            
                
                    <Route exact path="/" element={<Home/>}/>
                    {/* <Route exact path="/Contect" element={<Contect/>}/> */}
                
                
                   
                </Routes>
                
            </BrowserRouter>
            {/* <Footer /> */}
        </>
    )
}

export default Router
